<template>
  <div
    class="sidepanesearch__container h-full w-full p-8 pb-0 overflow-hidden"
  >
    <div class="mb-8">
      <div class="search-filters pl-2 pr-2 mb-6 flex-grow">
        <div class="flex flex-wrap">
          <div class="mb-4 mr-4">
            <template v-if="currentState.participating">
              <h2 class="text-22 mb-4 paint-msg">
                Currently showing PaintCare sites within <strong>{{ currentState.label }}</strong>.
              </h2>
            </template>
            <template v-if="!currentState.participating">
              <h2 class="text-22 mb-4 paint-msg">
                Currently showing paint drop off sites in <strong>{{ currentState.label }}</strong>
              </h2>
              <h3 class="text-left text-16">{{ currentState.label }} is not a PaintCare State.</h3>
            </template>
          </div>
        </div>
        <div class="flex justify-between mt-6">
          <div class="w-2/3 mr-6">
            <s-geocoder :intro="true" type="in-map" />
          </div>
          <div class="w-1/3 mb-2">
            <dropdown
              v-model="selectedFilter"
              :outline="false"
              :multiple="false"
              class="site-type-dropdown"
            >
              <option
                v-for="site in siteTypes"
                :key="site"
                :value="site"
              >
                {{ site }}
              </option>
            </dropdown>
          </div>
        </div>
      </div>
      <div class="result-items flex-shrink ml-2" v-if="spatialFilter">
        <SiteCountInformation />
      </div>
      <v-title-divider class="" />
    </div>
    <SearchList/>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import {
  usageReuseFilter,
  usageHHWFilter,
} from '@/common/utils/translator.utils';

import titledivider from '@/components/atoms/title-divider.vue';
import { states } from '@/common/config/map.config';
import InfoMessage from '../atoms/InfoMessage.vue';
import SearchList from '../molecules/SearchList.vue';
import SiteCountInformation from '../atoms/SiteCountInformation.vue';
import Dropdown from '../../atoms/dropdown.vue';
import SGeocoder from '../../atoms/s-geocoder.vue';
import { useGeocoding } from '@/common/composition/map';

export default {
  name: 'SidePaneSearch',
  components: {
    'v-title-divider': titledivider,
    InfoMessage,
    SearchList,
    SiteCountInformation,
    Dropdown,
    's-geocoder': SGeocoder,
  },
  props: {},
  setup() {
    useGeocoding(); // bound to bind listeners
    const store = useStore();
    const selectedFilter = ref('All Sites');
    const siteTypes = ref(['All Sites', 'HHW', 'Reuse']);
    const spatialFilter = computed(() => store.state.spatialFilter);
    const currentState = computed(() => {
      return store.getters.fullCurrentState;
    });

    watch(selectedFilter, () => {
      switch (selectedFilter.value) {
        case 'HHW':
          store.commit('APPEND_FILTER', usageHHWFilter);
          break;
        case 'Reuse':
          store.commit('APPEND_FILTER', usageReuseFilter);
          break;
        default:
          store.commit('RESET_FILTER', 'Pin_Code');
          break;
      }
    });

    return {
      siteTypes, selectedFilter, spatialFilter, currentState,
    };
  },
};
</script>

<style>

.sidepanesearch__container{
  display: flex;
  flex-direction: column;
}

.search-filters{
  min-height: 11rem;
}

.result-items{
  min-height: 3rem;
}

.paint-msg{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: clamp(14px, 3vw, 20px);
  line-height: 1.5;
  max-width: 100%;
}

</style>
