const HHW = 'HHW';
const HHW_REUSE = 'HHW Reuse';
const SM = '5 Gal';
const SM_REUSE = '5 Gal Reuse';
const MD = '20 Gal';
const MD_REUSE = '20 Gal Reuse';
const LG = 'Over 20';
const LG_REUSE = 'Over 20 Reuse';

const REUSE_CODES = [HHW_REUSE, SM_REUSE, MD_REUSE, LG_REUSE];
const HHW_CODES = [HHW, HHW_REUSE];
const LIMIT_CODES = [SM, SM_REUSE, MD, MD_REUSE, LG, LG_REUSE];

const formatLimitText = (code) => {
  if (code === SM || code === SM_REUSE) {
    return 'Accepts up to 5 Gal.';
  }
  if (code === MD || code === MD_REUSE) {
    return 'Accepts up to 20 Gal.';
  }
  if (code === LG || code === LG_REUSE) {
    return 'Accepts over 20 Gal.';
  }
  return 'Usage info not available';
};

const getUsageInfo = (code) => {
  const info = {
    isReuse: false,
    isHHW: false,
    limitText: null,
  };

  if (REUSE_CODES.includes(code)) {
    info.isReuse = true;
  }

  if (HHW_CODES.includes(code)) {
    info.isHHW = true;
  }

  if (LIMIT_CODES.includes(code)) {
    info.limitText = formatLimitText(code);
  }

  return info;
};

const getSiteInfo = (site) => {
  const siteUsageCode = site.Pin_Code;
  const phone = site.Phone_Line;
  const info = getUsageInfo(siteUsageCode);
  const name = site.Name;
  const location = `${site.City}, ${site.State}`;

  const siteInfo = {
    ...info,
    name,
    location,
    phone,
  };

  return siteInfo;
};

const usageReuseFilter = {
  Pin_Code: REUSE_CODES,
};

const usageHHWFilter = {
  Pin_Code: HHW_CODES,
};

const usageReuseHHWFilter = {
  Pin_Code: [...HHW_CODES, ...REUSE_CODES],
};

function isEmpty(str) {
  return (!str || str.trim().length === 0);
}

const commentCompiler = (comments) => {
  /** Comments from PaintCare API are included in 4 different comment fields. Include comments when present (not null, not empty  
   * @returns String 
  */
  return comments.filter(comment => !isEmpty(comment)).join(' ');
};

const addressCompiler = ({ location, zip, city, state }) => {
  return `${location}, ${city}, ${state}, ${zip}`;
};

const rollbackDetails = {
  address: null,
  comments: null,
  ForType: null,
  HoursDisplay: null,
  Reuse_Statement: null,
  URL: null,
  PaintCareStatement: null,

}

const gatherExtendedDetails = (fieldData) => {
  /**
   * Returns object which will be used by 'more site details' area; handles logic to identify which detail info should be shown based on presence of attributes from api. 
   */
  try {
    const location = fieldData['Site_Address'];
    const zip = fieldData['Zip'];
    const city = fieldData['City'];
    const state = fieldData['State'];
    const Comment1 = fieldData['Comment1'];
    const Comment2 = fieldData['Comment2'];
    const Comment3 = fieldData['Comment3'];
    const Comment4 = fieldData['Comment4'];
    const ForType = fieldData['For_Type_to_Display'];
    const HoursDisplay = fieldData['Hours to Display'];
    const Reuse_Statement = fieldData['Reuse_Statement'];
    const URL = fieldData['URL'];
    const PaintCareStatement = fieldData['Visit_PaintCare_Statement'];

    const comments = commentCompiler([Comment1, Comment2, Comment3, Comment4]);
    const address = addressCompiler({ location, zip, city, state });

    return {
      address: !isEmpty(address) ? address : null,
      comments: !isEmpty(comments) ? comments : null,
      ForType: !isEmpty(ForType) ? ForType : null,
      HoursDisplay: !isEmpty(HoursDisplay) ? HoursDisplay : null,
      Reuse_Statement: !isEmpty(Reuse_Statement) ? Reuse_Statement : null,
      URL: !isEmpty(URL) ? URL : null,
      PaintCareStatement: !isEmpty(PaintCareStatement) ? PaintCareStatement : null,
    }

  } catch (err) {
    console.error(err);
    return {
      ...rollbackDetails
    }
  }
}

export {
  gatherExtendedDetails,
  getUsageInfo,
  getSiteInfo,
  REUSE_CODES,
  HHW_CODES,
  usageReuseFilter,
  usageHHWFilter,
  usageReuseHHWFilter,
};
