<template>
  <div class="inline-flex justify-center items-center">
    <template v-if="filter"> 
      <GlobeIcon class="w-4 text-blue mr-3 animate-ping" />
      <span :class="{hidden: !filter}"> Map Filter set!&nbsp;</span>
      Only {{ siteCount }}  sites shown on map are visible below
    </template>
  </div>
</template>

<script>
import { GlobeIcon } from '@heroicons/vue/outline';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'SiteCountInformation',
  components: {
    GlobeIcon,
  },
  setup() {
    const store = useStore();
    const filter = computed(() => store.state.spatialFilter);
    const siteCount = computed(() => store.getters.VISIBLE_SITE_COUNT);
    return { siteCount, filter };
  },
};
</script>
