import { GeolocateControl } from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, onMounted, onBeforeUnmount  } from 'vue';
import { emitter } from '@/common/utils/emitter';

import { handleGeocodingResult, handleHomeGeocodingResult } from '@/common/utils/map.utils';
import mapboxgl from '@/common/utils/mapbox-gl-helper';
import { MapboxGLButtonControl } from '@/common/classes/mapbox.js';
import { interactions } from '@/common/composition/EventBus';
import { states } from '@/common/config/map.config';


export const useGeolocation = () => {
  const geolocation = new GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: true,
    showUserLocation: false,
  });

  return { geolocation };
};

const filterStates = (item) => { 
  if (item.place_type && item.place_type[0] === 'region') return false;
  return true
}
  
function forwardGeocoder(query) {
  const matchingFeatures = [];
  for (const feature of states) {
  // Handle queries with different capitalization
  // than the source data by calling toLowerCase().
  const title = feature.label; 
  if (
  title
  .toLowerCase()
  .includes(query.toLowerCase())
  ) {
  feature['place_name'] = ` ${title} (Show entire state)`;
  feature['bounds'] = feature.bounds;
  feature['place_type'] = ['place'];
  matchingFeatures.push(feature);
  }
  }
  return matchingFeatures;
  }
   


export const geocode = (type) => {
  /**
   * @param type - string, 'home' or 'map'
   */

  const geocoder = new MapboxGeocoder({
    accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
    mapboxgl,
    placeholder: 'Search by zip, city, address, etc',
    types: 'country, region, postcode, district, place, locality, neighborhood, address',
    countries: 'US',
    marker: false,
    flyTo: false,
    filter: filterStates,
    localGeocoder: forwardGeocoder,
  });

  const handler = type === 'home' ? handleHomeGeocodingResult : handleGeocodingResult;
  geocoder.on('result', handler);
  return { geocoder };
};
export const information = () => {
  const { infoClicked } = interactions();

  const infoBtn = new MapboxGLButtonControl({
    className: 'mapbox-gl-info',
    title: 'Information Button',
    eventHandler: infoClicked,
  });

  return { infoBtn };
};

export const useLegend = () => {
  const { legendClicked } = interactions();

  const legendBtn = new MapboxGLButtonControl({
    className: 'mapbox-gl-legend',
    title: 'Map Legend',
    eventHandler: legendClicked,
  });

  return { legendBtn };
};


export const featureBehavior = () => {
  const zoomToFeature = (map, evt) => {
    map.flyTo({
      center: evt.features[0].geometry.coordinates,
    });
  };

  return { zoomToFeature };
};

export const useGeocoding = () => {
  const store = useStore();
  const router = useRouter();
  const geocodedState = ref();
  const geocodedLocation = ref([]);
  // eslint-disable-next-line prefer-destructuring
  const selectedState = store.state.selectedState; 
  

  const setState = async (value) => {
    store.commit('SET_STATE', value);
    await store.dispatch('QUERY_SITES_BY_STATE', value);
  };

  onMounted(async () => {
    emitter.on('update:geocode-result', async ({ lat, lng, bbox, state, type }) => {
      geocodedState.value = state;
      // start processing
      if (store.state.selectedState !== state) {
        await setState(state); // only if state is not already set
      } 
      if (type === 'map' && lat && lng) { // lack of lat and lng means user passed whole state, not location from in-map geocoder
        geocodedLocation.value = [lat, lng];
        const location = {
          pnt: [lng, lat],
          bbox, 
        };
        store.commit('SET_GEOCODED_LOCATION', location);
        store.dispatch('UPDATE_SITE_PROXIMITY', [lng, lat]);
      }
      else if (type === 'home' && lat && lng) { // lack of lat and lng means user passed whole state, not location from in-map geocoder
        geocodedLocation.value = [lat, lng];
        const location = {
          pnt: [lng, lat],
          bbox, 
        };
        store.commit('SET_GEOCODED_LOCATION', location);
        store.dispatch('UPDATE_SITE_PROXIMITY', [lng, lat]);
        router.push({ name: 'state-view', params: { id: state } });


      }
      else if (type === 'home') {
        router.push({ name: 'state-view', params: { id: state } });
      }
      else if (type === 'map' && bbox) {
        const location = {
          pnt: [lng, lat],
          bbox, 
        };
        store.commit('SET_GEOCODED_LOCATION', location);
      }

      // stop processing
    });
  });

  onBeforeUnmount(() => {
    emitter.off('update:geocode-result');
  });

  return {
    selectedState,
    geocodedLocation,
    setState,
  };
};
