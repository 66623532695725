import { createStore } from 'vuex';
import { API, AWS_API } from '@/common/services/api.core';
import { markerSymbol } from '@/common/utils/map.utils';
import { featureCollection, point } from '@turf/helpers';
import distance from '@turf/distance';
import { emitter } from '@/common/utils/emitter';

import { states, US_BOUNDS } from '@/common/config/map.config';
import { siteSearch } from '@/common/utils';

export const store = {
  state: {
    map: null,
    booted: false,
    sites: [],
    selectedState: '',
    selectedSite: null,
    geocodedLocation: null,
    geocodedBounds: null,
    siteFilters: {
      Active: 'Y',
    },
    visibleIds: null,
    spatialFilter: false,
  },
  getters: {
    spatialSites: (state, getters) => {
      const features = getters.filteredSites.map((site) => {
        // Create array of GeoJSON features for site points
        const { Latitude: latitude, Longitude: longitude, ...properties } = site;
        return point([Number(longitude), Number(latitude)], { ...properties, 'marker-symbol': markerSymbol(properties.Pin_Code) });
      }).filter((f) => f);
      return featureCollection(features);
    },
    filteredSites: (state) => state.sites.filter(siteSearch, state.siteFilters),
    visibleSites: (state, getters) => {
      return getters.filteredSites.filter(siteSearch, { Location_ID: state.visibleIds });
    },
    GET_SITE_BY_ID: (state) => (id) => {
      return state.sites.find((site) => site.Location_ID === id);
    },
    bounds: (state) => {
      const match = states.find((s) => s.name === state.selectedState);
      if (typeof match === 'object' && typeof match.bounds === 'object' && match.bounds.length === 2) {
        return match.bounds;
      }
      return US_BOUNDS;
    },
    fullCurrentState: (state) => {
      const { selectedState } = state; 
      const fullState = states.find((s) => s.name === selectedState);
      return {
        label: fullState.label,
        participating: fullState.participating,
      };
    },
    VISIBLE_SITE_COUNT: (state) => {
      if (state.spatialFilter) {
        if (state.visibleIds && state.visibleIds.length) {
          return state.visibleIds.length > 0 ? state.visibleIds.length : 0;
        }
        return 0;
      }
      return state.sites.length;
    },
  },
  mutations: {
    TOGGLE_LAYER: (state, payload) => {
      state.map.setLayoutProperty(
        payload.layer_id,
        'visibility',
        payload.visible ? 'visible' : 'none',
      );
    },
    SET_SPATIAL_FILTER: (state, value) => {
      state.spatialFilter = value;
    },
    SET_MAP: (state, payload) => {
      state.map = payload;
    },
    SET_SITES: (state, payload) => {
      state.sites = payload;
    },
    SET_INITIAL_SITES: (state, payload) => {
      state.sites = payload.map((site) => {
        const { fieldData } = site;
        return { ...fieldData };
      });
    },
    SET_VISIBLE_IDS: (state, payload) => {
      state.visibleIds = payload;
    },
    BOOT_APP: (state) => {
      state.booted = true;
    },
    SET_STATE: (state, payload) => {
      state.spatialFilter = false;
      emitter.emit('clear:site-details');
      state.selectedState = payload;
    },
    SET_SITE: (state, payload) => {
      state.selectedSite = payload;
    },
    SET_GEOCODED_LOCATION: (state, payload) => {
      state.geocodedLocation = payload;
    },
    CLEAR_GEOCODED_LOCATION: (state) => {
      state.geocodedLocation = null;
    },
    APPEND_FILTER: (state, payload) => {
      state.siteFilters = { ...state.siteFilters, ...payload };
    },
    RESET_FILTER: (state, payload) => {
      delete state.siteFilters[payload];
    },
    UPDATE_GEOCODED_BOUNDS: (state, payload) => {
      state.geocodedBounds = payload;
    }, 
    CLEAR_GEOCODED_BOUNDS: (state) => {
      state.geocodedBounds = null;
    }
  },
  actions: {
    QUERY_SITES_BY_STATE: async ({ commit }, payload) => {
      emitter.emit('start:loader');
      const api = new AWS_API();
      const sites = await api.getSitesByState(payload);
      if (sites) {
        commit('SET_INITIAL_SITES', sites);
        emitter.emit('cancel:loader');
      } else {
        emitter.emit('cancel:loader');
        throw new Error('Fetching data was unaccessible from API');     
      }
    },
    GET_FEATURE: ({ getters }, id) => {
      return getters.spatialSites.features.find((f) => f.properties.Location_ID === id.toString());
    },
    SET_MOUSE_LOCATION: async ({ commit }, payload) => {
      commit('SET_MOUSE_LOCATION', payload);
    },
    SET_MAP_INSTANCE: ({ commit }, payload) => {
      commit('SET_MAP', payload);
    },
    UPDATE_SITE_PROXIMITY: ({ commit, state }, payload) => {
      const [targetLon, targetLat] = payload;
      const updatedSites = state.sites.map((site) => {
        const { Latitude, Longitude } = site;
        const options = { units: 'miles' };
        const proximity = distance([Longitude, Latitude], [targetLon, targetLat], options);
        return {
          ...site,
          distanceTo: proximity
        }
      }).sort((a, b) => {
        return a.distanceTo - b.distanceTo;
      });

      commit('SET_SITES', updatedSites);
    },
  },
  modules: {
  },
};

export default createStore(store);
