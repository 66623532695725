<template>
  <div id="mapContainer" ref="mapContainer" class="flex">
    <v-button v-if="switchState"
      class="z-50 absolute bottom-2 left-2 border border-blue bg-white text-black switch-btn flex"
      @click="handleStateSwitch">
      <ArrowCircleRightIcon class="h-8 w-8 self-center text-blue mr-6" /> <span class="switch-text">Switch active state to
        {{ switchState }}</span>
    </v-button>
    <template v-if="!isMobile">
      <v-legend-popup />
    </template>
  </div>
</template>

<script>
import {
  defineComponent, onMounted, onBeforeUnmount, ref, computed, watch, watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import { emitter } from '@/common/utils/emitter';
import { ArrowCircleRightIcon } from '@heroicons/vue/outline';

import { lookupStateBounds } from '@/common/utils/map.utils.js';

import {
  Map, NavigationControl
} from 'mapbox-gl';
import mapboxgl from '@/common/utils/mapbox-gl-helper';

import { interactions } from '@/common/composition/EventBus';
import { US_BOUNDS } from '@/common/config/map.config.js'
import Button from '@/components/atoms/button.vue';
import { useRouter } from 'vue-router';
import {
  useGeolocation, geocode, information, featureBehavior, useLegend,
} from '@/common/composition/map';
import {
  PAINT_STYLE, ACTIVE_FILL_OPACITY, INACTIVE_FILL_OPACITY,
} from '@/common/config/map.style';

import LegendPopup from '../LocatorDesktop/molecules/LegendPopup.vue';

// const addGeocoderToFilters = (map, geocoder) => {
//   const targetEl = document.getElementById('desktop-geocoder');
//   targetEl.appendChild(geocoder.onAdd(map));
// };

const DEFAULT_ZOOM_LEVEL = 11;
const DEFAULT_ZOOM_PADDING = 20;

export default defineComponent({
  components: {
    'v-button': Button,
    ArrowCircleRightIcon,
    'v-legend-popup': LegendPopup,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup(props, ctx) {
    const { isMobile } = props; // loses reactivity so may cause issues if you toggle between desktop and mobile on browser.
    const store = useStore();
    const router = useRouter();
    const { markerClicked, stateSwitch } = interactions();
    const { zoomToFeature } = featureBehavior();

    const switchState = ref();
    const hoverState = ref();

    const map = ref(null);
    const mapLoaded = ref(false);
    const mapContainer = ref();

    const handleStateSwitch = () => {
      stateSwitch(switchState.value);
      const bounds = lookupStateBounds(switchState.value);
      if (bounds) {
        map.value.fitBounds(bounds);
      }
      switchState.value = null;
    };

    const bounds = computed(() => store.getters.bounds);

    const selectedState = computed(() => store.state.selectedState);

    const geocodedLocation = computed(() => store.state.geocodedLocation);

    const spatialSites = computed(() => store.getters.spatialSites);

    const { geolocation } = useGeolocation();

    watch(spatialSites, () => {
      if (spatialSites.value.features.length && map.value && mapLoaded.value) {
        const source = map.value.getSource('pointFeatures');
        source.setData(spatialSites.value);
      }
    });

    const flyToBounds = async (mapBounds) => {
      map.value.fitBounds(mapBounds, { animate: true, padding: DEFAULT_ZOOM_PADDING });
    };

    watch([geocodedLocation, map], () => {
      if (geocodedLocation.value && geocodedLocation.value.bbox && map.value) {
        flyToBounds(geocodedLocation.value.bbox);
      }
    });

    const loadData = () => {
      map.value.getSource('pointFeatures').setData(spatialSites.value);
    };

    watchEffect(() => {
      if (mapLoaded.value) {
        const name = selectedState.value || 'NONE';
        const expression = ['match', ['get', 'name'], name, ACTIVE_FILL_OPACITY, INACTIVE_FILL_OPACITY];
        map.value.setPaintProperty('states', 'fill-opacity', expression);
        map.value.setFilter('states-lines', ['in', 'name', ...[switchState.value, hoverState.value].filter((v) => !!v)]);
      }
    });

    // const setVisibleIds = () => {
    //   const visibleFeatures = map.value.queryRenderedFeatures({ layers: ['paint-sites'] });
    //   const ids = visibleFeatures.map((f) => f.properties.Location_ID);
    //   store.commit('SET_VISIBLE_IDS', ids);
    // };
    const loadMap = async () => {
      const newBounds = store.state.selectedState ? lookupStateBounds(store.state.selectedState) : null;

      const padBounds = (boundsArray) => {
        let paddedBounds = boundsArray
        // Calculate the 15% increase for both the longitude and latitude
        const lngDelta = (paddedBounds[1][0] - paddedBounds[0][0]) * 0.15;
        const latDelta = (paddedBounds[1][1] - paddedBounds[0][1]) * 0.15;

        // Expand the bounds object by 20%
        paddedBounds[0][0] -= lngDelta;
        paddedBounds[0][1] -= latDelta;
        paddedBounds[1][0] += lngDelta;
        paddedBounds[1][1] += latDelta;

        return paddedBounds

      }

      const options = {
        attributionControl: false,
        style: PAINT_STYLE,
        container: mapContainer.value,
        accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
        maxBounds: US_BOUNDS,
        bounds: padBounds(newBounds),
      };
      map.value = await new Map(options);

      map.value.addControl(new mapboxgl.AttributionControl({
        compact: false
      }));

      store.dispatch('SET_MAP_INSTANCE', map.value);
      map.value.addControl(geolocation, 'bottom-right');

      const { geocoder } = geocode();

      onBeforeUnmount(() => {
        emitter.off('update:change-site');
        emitter.off('update:geocode-result');
      });

      watch(
        () => store.state.siteFilters,
        () => {
          const source = map.value.getSource('activePoint');
          source.setData({
            type: 'Point',
            coordinates: [],
          });
        },
      );

      watch(
        () => store.state.selectedState,
        () => {
          const source = map.value.getSource('activePoint');
          source.setData({
            type: 'Point',
            coordinates: [],
          });
        },
      );

      const { infoBtn } = information();
      map.value.addControl(infoBtn, 'bottom-right');

      if (!isMobile) {
        map.value.addControl(new NavigationControl(), 'top-right');
        const { legendBtn } = useLegend();
        map.value.addControl(legendBtn, 'bottom-right');
      }

      map.value.on('load', (ctx) => {
        mapLoaded.value = true;
        if (spatialSites.value) {
          loadData();
        };
        // setVisibleIds();
        if (store.state.geocodedLocation && store.state.geocodedLocation.bbox) {
          flyToBounds(store.state.geocodedLocation.bbox);
        }
        else if (store.state.geocodedBounds && store.state.geocodedBounds) {
          flyToBounds(store.state.geocodedBounds);
        };
      });

      if (isMobile) {
        map.value.addControl(geocoder, 'top-left');
      }

      // Event handler for mouse click events on point layer
      map.value.on('click', 'paint-sites', (e) => {
        // It's safe to assume we have a feature here because we are listening to the layer.
        const { features: [{ properties: { Location_ID: featureId } }] } = e;
        markerClicked(featureId);
        zoomToFeature(map.value, e);
        const feature = e.features[0];
        const source = map.value.getSource('activePoint');
        source.setData(feature);
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });


      const setPopup = (e) => {
        map.value.getCanvas().style.cursor = 'pointer';

        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.Name;
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        // // Populate the popup and set its coordinates
        // // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map.value);
      }

      const removePopup = () => {
        map.value.getCanvas().style.cursor = '';
        popup.remove();
      };

      map.value.on('mouseenter', 'paint-sites', (e) => {
        // Change the cursor style as a UI indicator.
        map.value.getCanvas().style.cursor = 'pointer';
        const currentZoom = map.value.getZoom();
        if (currentZoom >= 7) {
          setPopup(e);
        }

      });

      map.value.on('mouseleave', 'paint-sites', () => {
        map.value.getCanvas().style.cursor = '';
        removePopup();
        // debounce(() => removePopup());

      })

      map.value.on('click', 'states', (e) => {
        const { properties: { name } } = e.features[0] || { properties: {} };
        switchState.value = null;
        if (selectedState.value !== name) switchState.value = name;
      });

      map.value.on('mousemove', 'states', (e) => {
        const { properties: { name } } = e.features[0] || { properties: {} };
        map.value.getCanvas().style.cursor = selectedState.value === name ? '' : 'pointer';
        if (selectedState.value !== name) {
          hoverState.value = name;
        } else {
          hoverState.value = null;
        }
      });

      map.value.on('mouseleave', 'states', () => {
        map.value.getCanvas().style.cursor = '';
        hoverState.value = null;
      });

      // map.value.on('moveend', () => setVisibleIds());

      emitter.on('update:change-site', async ({ site }) => {
       
        // source.setData({
        //   type: 'Point',
        //   coordinates: [],
        // });


        const { Longitude, Latitude } = site;
        map.value.flyTo({
          center: [Longitude, Latitude],
          padding: DEFAULT_ZOOM_PADDING,
          animate: true,
        });
        //const feature = await store.dispatch('GET_FEATURE', site.Location_ID);

        const id = site.Location_ID;
        const { features } = store.getters.spatialSites;
        const feature = features.find((f) => f.properties.Location_ID === id);
        // eslint-disable-next-line no-underscore-dangle
        const source = map.value.getSource('activePoint');
        // const point = {
        //   type: 'FeatureCollection',
        //   features: [
        //     {
        //       type: 'Feature',
        //       geometry: {
        //         type: 'Point',
        //         coordinates: [
        //           Longitude,
        //           Latitude
        //         ]
        //       },
        //       properties: {
        //         name: 'highlight-marker',
        //       }
        //     },
        //   ]
        // }
        source.setData(feature);
      });

      // Emit up to parent component to signal that map load is complete.
      // Parent components should access the mapboxgl `Map` instance from
      // the store at `store.state.map` and add their own specific listeners.
      ctx.emit('map:resources_loaded');

      return { map };
    };

    onMounted(async () => {
      await loadMap();
    });

    watch(spatialSites, () => {
      if (mapLoaded.value) {
        loadData();
      }
    });

    return {
      mapContainer,
      map,
      spatialSites,
      selectedState,
      bounds,
      switchState,
      stateSwitch,
      hoverState,
      handleStateSwitch,
    };
  },
});
</script>

<style scoped>
/* Placeholder basic style until Tailwind / broader application is set up */
#mapContainer {
  height: 100%;
  width: 100%;
}

.switch-btn {
  /* border: 5px solid yellow; */
  background: yellow;
}

/* 
.switch-text:hover { 
  color: blue;
} */

.switch-btn:hover {
  border-color: blue !important;
}
</style>
