<template>
  <div
    class="relative rounded-sm w-full"
    :class="classObject"
    @click="$refs.dropdown.click()"
  >
    <select
      ref="dropdown"
      class="appearance-none rounded-sm p-6 text-16 text-black bg-grey-light-1 w-full"
      :multiple="multiple"
      v-model="value"
    >
      <slot />
    </select>
    <ChevronDownIcon class="w-6 h-6 text-blue dropdown-icon" @click="handleClick()"></ChevronDownIcon>
  </div>
</template>

<script>
import { toRef, computed } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/outline';

export default {
  components: {
    ChevronDownIcon,
  },
  props: {
    outline: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    modelValue: String,
    multiple: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const hasOutline = toRef(props, 'outline').value;

    const classObject = computed(() => {
      if (hasOutline) {
        return 'bg-white border border-grey';
      }
      return 'bg-grey-light-1';
    });

    const handleClick = () => {
      console.log('clicked');
    };


    return { classObject, handleClick };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      },
    },
  },
};
</script>

<style scoped>

select:focus-visible {
  outline: none;
  background-color: #FFFFFF !important;
  box-shadow: 0px 0px 0px 1px #026FCF;
}
.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}
</style>
