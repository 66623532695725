<template>
  <div class="mb__container flex h-full w-full">
    <keep-alive>
      <component
        :is="mobileComponent"
        @toggle:view="toggleView"
      />
    </keep-alive>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';

const SiteList = defineAsyncComponent(() => import('./organisms/SiteList.vue'));
const SiteMap = defineAsyncComponent(() => import('./organisms/SiteMap.vue'));

export default {
  name: 'LocatorMobile',
  setup(props) {
    const isMap = ref(true);
    const mobileComponent = computed(() => (isMap.value === true ? SiteMap : SiteList));

    const toggleView = () => isMap.value = !isMap.value;

    return { toggleView, mobileComponent, isMap };
  },
};
</script>
