<template>
  <div class="inline-flex justify-self-start items-center content-start">
    <InformationCircleIcon class="w-10 text-blue mr-3" />
    <slink class="text-16"> See details about the Site Locator </slink>
  </div>
</template>


<script>
import { InformationCircleIcon } from '@heroicons/vue/outline';
import slink from '@/components/atoms/s-link.vue';

export default {
  name: 'SidePaneSearch',
  components: {
    InformationCircleIcon,
    slink,
  }
};
</script>
