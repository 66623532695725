import { createRouter, createWebHashHistory } from 'vue-router';
import SiteView from '@/views/SiteView.vue';
import SiteTest from '@/views/SiteTest.vue';

export const routes = [
  {
    path: '/',
    redirect: '/national',
    name: 'home',
    meta: {
      env: 'prod',
    },
  },
  {
    path: '/state/:id',
    name: 'state-view',
    component: SiteView,
    meta: {
      env: 'prod',
    },
  },
  {
    path: '/national',
    name: 'national-view',
    meta: {
      env: 'prod',
    },
    // route level code-splitting
    // this generates a separate chunk (site-index.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "site-index" */ '../views/SiteIndex.vue'),
  },
  {
    path: '/siteview',
    name: 'site-view',
    component: SiteTest,
    meta: {
      env: 'dev',
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
