<template>
  <div class="h-full w-full p-4">
    <component :is="adaptiveComponent" />
  </div>
</template>

<script>
import useBreakpoints from '@/common/ui/breakpoints';
import { computed, defineAsyncComponent } from 'vue';

const mainDesktop = defineAsyncComponent(() => import('@/components/LocatorDesktop/index.vue'));
const mainMobile = defineAsyncComponent(() => import('@/components/LocatorMobile/index.vue'));

export default {
  name: 'SiteView',
  props: {},
  setup() {
    const { type } = useBreakpoints();
    const adaptiveComponent = computed(() => (type.value === 'xs' ? mainMobile : mainDesktop));

    return { type, adaptiveComponent };
  },
};

</script>

<style scoped>

</style>
