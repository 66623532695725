const FCC_BASE_URL = 'https://geo.fcc.gov';
const FCC_NAMESPACE = 'api';
class FccAPI {
  constructor() {
    this.baseUrl = FCC_BASE_URL;
    this.namespace = FCC_NAMESPACE;
  }

  // eslint-disable-next-line class-methods-use-this
  errorHandler(error) {
    // send to sentry
    // send to UI that we have an error
    return error;
  }

  async makeRequest(url, opt) {
    /** Generic HTTP Helper Method */

    const options = {
      'Content-Type': 'application/json',
      'Referrer-Policy': 'no-referrer-when-downgrade',
      ...opt,
    };

    try {
      const response = await fetch(url, options);
      if (response && (response.status < 300)) {
        return response.json();
      }
      return this.errorHandler('not a 200');
    } catch (error) {
      console.log(error);
      return this.errorHandler(error);
    }
  }

  async queryLocation( lat, lng ) {
    /** Takes Location properties and fetches info from Census REST API */

    const url = `${this.baseUrl}/${this.namespace}/census/area?lat=${lat}&lon=${lng}`;

    const options = {
      method: 'GET'
    };

    return this.makeRequest(url, options);
  }
};

export {
  FccAPI
}
