<template>
  <h2 class="text-12 font-bold">
    <slot />
  </h2>
</template>

<script>
export default {
  name: 'SH#',
};
</script>
