<template>
  <div
    id="geocoder"
    class="w-full"
    :class="{ intro : intro }"
  />
</template>

<script>
import { onMounted } from 'vue';
import { geocode } from '@/common/composition/map';

export default {
  props: {
    intro: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    type: {
      type: String, 
      required: true, 
      default: () => 'home',
    }
  },
  setup(props, { emit }) {
    const initGeocoder = () => {
      const geocoderType = props.type; 
      const { geocoder } = geocode(geocoderType);

      geocoder.addTo('#geocoder');
    };

    onMounted(initGeocoder);
  },
};
</script>
