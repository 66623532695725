export const US_BOUNDS = [
  [-179.714843, 8.1784018],
  [-47.8125, 73.42842364106816],
];

export const states = [
  {
    name: 'AL',
    isState: true,
    label: 'Alabama',
    participating: false,
    bounds: [
      [-88.473227, 30.221132],
      [-84.890894, 35.008028],
    ],
  },
  {
    name: 'AK',
    label: 'Alaska',
    isState: true,
    participating: false,
    bounds: [
      [-179.14734, 51.219862],
      [179.77847, 71.352561],
    ],
  },
  {
    name: 'AZ',
    label: 'Arizona',
    isState: true,
    participating: false,
    bounds: [
      [-114.813991, 31.332177],
      [-109.045223, 37.00426],
    ],
  },
  {
    name: 'AR',
    label: 'Arkansas',
    isState: true,
    participating: false,
    bounds: [
      [-94.617919, 33.004106],
      [-89.644838, 36.4996],
    ],
  },
  {
    name: 'CA',
    label: 'California',
    isState: true,
    participating: true,
    bounds: [
      [-124.409591, 32.534156],
      [-114.134427, 42.009518],
    ],
  },
  {
    name: 'CO',
    label: 'Colorado',
    isState: true,
    participating: true,
    bounds: [
      [-109.060253, 36.992426],
      [-102.041524, 41.003444],
    ],
  },
  {
    name: 'CT',
    label: 'Connecticut',
    isState: true,
    participating: true,
    bounds: [
      [-73.6, 41],
      [-71.6, 41.8],
    ],
  },
  {
    name: 'DE',
    label: 'Delaware',
    isState: true,
    participating: false,
    bounds: [
      [-75.788658, 38.451013],
      [-75.048939, 39.839007],
    ],
  },
  {
    name: 'DC',
    label: 'District Of Columbia',
    isState: true,
    participating: true,
    bounds: [
      [-77.3, 38.6],
      [-76.8, 39.2],
    ],
  },
  {
    name: 'FL',
    label: 'Florida',
    isState: true,
    participating: false,
    bounds: [
      [-87.634938, 24.544701],
      [-80.031362, 31.000888],
    ],
  },
  {
    name: 'GA',
    label: 'Georgia',
    isState: true,
    participating: false,
    bounds: [
      [-85.605165, 30.357851],
      [-80.840549, 35.000771],
    ],
  },
  {
    name: 'HI',
    label: 'Hawaii',
    isState: true,
    participating: false,
    bounds: [
      [-160.24961, 18.917466],
      [-154.809379, 22.23317],
    ],
  },
  {
    name: 'ID',
    label: 'Idaho',
    isState: true,
    participating: false,
    bounds: [
      [-117.243027, 41.988057],
      [-111.043564, 49.001146],
    ],
  },
  {
    name: 'IL',
    label: 'Illinois',
    isState: true,
    participating: false,
    bounds: [
      [-91.513079, 36.970298],
      [-87.496494, 42.508302],
    ],
  },
  {
    name: 'IN',
    label: 'Indiana',
    isState: true,
    participating: false,
    bounds: [
      [-88.058499, 37.771742],
      [-84.784579, 41.760592],
    ],
  },
  {
    name: 'IA',
    label: 'Iowa',
    isState: true,
    participating: false,
    bounds: [
      [-96.639704, 40.375501],
      [-90.140061, 43.501196],
    ],
  },
  {
    name: 'KS',
    label: 'Kansas',
    isState: true,
    participating: false,
    bounds: [
      [-102.051744, 36.993083],
      [-94.589933, 40.003162],
    ],
  },
  {
    name: 'KY',
    label: 'Kentucky',
    isState: true,
    participating: false,
    bounds: [
      [-89.571509, 36.497129],
      [-81.964971, 39.147458],
    ],
  },
  {
    name: 'LA',
    label: 'Louisiana',
    isState: true,
    participating: false,
    bounds: [
      [-94.043147, 28.925011],
      [-88.817017, 33.019457],
    ],
  },
  {
    name: 'ME',
    label: 'Maine',
    isState: true,
    participating: true,
    bounds: [
      [-71.084334, 43.059825],
      [-66.949895, 47.459686],
    ],
  },
  {
    name: 'MD',
    label: 'Maryland',
    isState: true,
    participating: false,
    bounds: [
      [-79.487651, 37.911717],
      [-75.048939, 39.723043],
    ],
  },
  {
    name: 'MA',
    label: 'Massachusetts',
    isState: true,
    participating: false,
    bounds: [
      [-73.508142, 41.237964],
      [-69.928261, 42.886589],
    ],
  },
  {
    name: 'MI',
    label: 'Michigan',
    isState: true,
    participating: false,
    bounds: [
      [-90.418136, 41.696089],
      [-82.412965, 48.190975],
    ],
  },
  {
    name: 'MN',
    label: 'Minnesota',
    isState: true,
    participating: true,
    bounds: [
      [-97.239209, 43.499356],
      [-89.489226, 49.384358],
    ],
  },
  {
    name: 'MS',
    label: 'Mississippi',
    isState: true,
    participating: false,
    bounds: [
      [-91.655009, 30.173943],
      [-88.097888, 34.996052],
    ],
  },
  {
    name: 'MO',
    label: 'Missouri',
    isState: true,
    participating: false,
    bounds: [
      [-95.774704, 35.995683],
      [-89.098843, 40.61364],
    ],
  },
  {
    name: 'MT',
    label: 'Montana',
    isState: true,
    participating: false,
    bounds: [
      [-116.050003, 44.358221],
      [-104.039563, 49.00139],
    ],
  },
  {
    name: 'NE',
    label: 'Nebraska',
    isState: true,
    participating: false,
    bounds: [
      [-104.053514, 39.999998],
      [-95.30829, 43.001708],
    ],
  },
  {
    name: 'NV',
    label: 'Nevada',
    isState: true,
    participating: false,
    bounds: [
      [-120.005746, 35.002085],
      [-114.039648, 42.002207],
    ],
  },
  {
    name: 'NH',
    label: 'New Hampshire',
    isState: true,
    participating: false,
    bounds: [
      [-72.557247, 42.69699],
      [-70.703799, 45.305476],
    ],
  },
  {
    name: 'NJ',
    label: 'New Jersey',
    isState: true,
    participating: false,
    bounds: [
      [-75.559614, 38.928519],
      [-73.893979, 41.357423],
    ],
  },
  {
    name: 'NM',
    label: 'New Mexico',
    isState: true,
    participating: false,
    bounds: [
      [-109.050173, 31.332301],
      [-103.001964, 37.000232],
    ],
  },
  {
    name: 'NY',
    label: 'New York',
    isState: true,
    participating: true,
    bounds: [
      [-79.762152, 40.496103],
      [-71.856214, 45.01585],
    ],
  },
  {
    name: 'NC',
    label: 'North Carolina',
    isState: true,
    participating: false,
    bounds: [
      [-84.321869, 33.844517],
      [-75.458659, 36.588117],
    ],
  },
  {
    name: 'ND',
    label: 'North Dakota',
    isState: true,
    participating: false,
    bounds: [
      [-104.0489, 45.935054],
      [-96.554507, 49.000687],
    ],
  },
  {
    name: 'OH',
    label: 'Ohio',
    isState: true,
    participating: false,
    bounds: [
      [-84.820159, 38.403202],
      [-80.518693, 41.977523],
    ],
  },
  {
    name: 'OK',
    label: 'Oklahoma',
    isState: true,
    participating: false,
    bounds: [
      [-103.002565, 33.616567],
      [-94.431215, 37.002206],
    ],
  },
  {
    name: 'OR',
    label: 'Oregon',
    isState: true,
    participating: true,
    bounds: [
      [-124.552441, 41.991794],
      [-116.463504, 46.271004],
    ],
  },
  {
    name: 'PA',
    label: 'Pennsylvania',
    isState: true,
    participating: false,
    bounds: [
      [-80.519891, 39.7198],
      [-74.689516, 42.26986],
    ],
  },
  {
    name: 'RI',
    label: 'Rhode Island',
    isState: true,
    participating: true,
    bounds: [
      [-71.862772, 41.146339],
      [-71.12057, 42.018798],
    ],
  },
  {
    name: 'SC',
    label: 'South Carolina',
    isState: true,
    participating: false,
    bounds: [
      [-83.353238, 32.0346],
      [-78.541087, 35.215402],
    ],
  },
  {
    name: 'SD',
    label: 'South Dakota',
    isState: true,
    participating: false,
    bounds: [
      [-104.057698, 42.479635],
      [-96.436589, 45.94545],
    ],
  },
  {
    name: 'TN',
    label: 'Tennessee',
    isState: true,
    participating: false,
    bounds: [
      [-90.310298, 34.982972],
      [-81.6469, 36.678118],
    ],
  },
  {
    name: 'TX',
    label: 'Texas',
    isState: true,
    participating: false,
    bounds: [
      [-106.645646, 25.837377],
      [-93.516407, 36.500704],
    ],
  },
  {
    name: 'UT',
    label: 'Utah',
    isState: true,
    participating: false,
    bounds: [
      [-114.052962, 36.997968],
      [-109.041058, 42.001567],
    ],
  },
  {
    name: 'VT',
    label: 'Vermont',
    isState: true,
    participating: true,
    bounds: [
      [-73.43774, 42.726853],
      [-71.464555, 45.016659],
    ],
  },
  {
    name: 'VA',
    label: 'Virginia',
    isState: true,
    participating: false,
    bounds: [
      [-83.675413, 36.540738],
      [-75.242266, 39.466012],
    ],
  },
  {
    name: 'WA',
    label: 'Washington',
    isState: true,
    participating: true,
    bounds: [
      [-124.733174, 45.543541],
      [-116.915989, 49.002494],
    ],
  },
  {
    name: 'WV',
    label: 'West Virginia',
    isState: true,
    participating: false,
    bounds: [
      [-82.644739, 37.201483],
      [-77.719029, 40.638801],
    ],
  },
  {
    name: 'WI',
    label: 'Wisconsin',
    isState: true,
    participating: false,
    bounds: [
      [-92.888114, 42.49192],
      [-86.805415, 47.07757],
    ],
  },
  {
    name: 'WY',
    label: 'Wyoming',
    isState: true,
    participating: false,
    bounds: [
      [-111.056888, 40.994746],
      [-104.05216, 45.005904],
    ],
  },
];
