/**
 * value is the API key which is OPEN TEXT ;(
 */

export const icons = {
  site: 'default',
  siteReuse: 'star',
  hhwReuse: 'star',
  hhw: 'default',
};

export const siteTypes = {
  'HHW': {
    label: 'Household Hazardous Waste',
    shortLabel: 'HHW',
    type: 'hhw',
    color: '00b7ff',
    hhw: true,
    reuse: false,
  },
  'HHW Reuse': {
    label: 'Household Hazardous Waste (Reuse)',
    shortLabel: 'HHW (Reuse)',
    type: 'hhwReuse',
    color: '00b7ff',
    hhw: true,
    reuse: true,
  },
  '5 Gal': {
    label: 'Accepts up to 5 gallons',
    shortLabel: 'Accepts up to 5 gal.',
    type: 'site',
    color: '8dc114',
    hhw: false,
    reuse: false,
    gallonLimit: 5,
  },
  '5 Gal Reuse': {
    label: 'Accepts up to 5 gallons (Reuse)',
    shortLabel: 'Accepts up to 5 gal. (Reuse)',
    type: 'siteReuse',
    color: '8dc114',
    hhw: false,
    reuse: true,
    gallonLimit: 5,
  },
  '20 Gal': {
    label: 'Accepts up to 20 gallons',
    shortLabel: 'Accepts up to 20 gal.',
    type: 'site',
    color: 'ffce00',
    hhw: false,
    reuse: false,
    gallonLimit: 20,
  },
  '20 Gal Reuse': {
    label: 'Accepts up to 20 gallons (Reuse)',
    shortLabel: 'Accepts up to 20 gal. (Reuse)',
    type: 'siteReuse',
    color: 'ffce00',
    hhw: false,
    reuse: true,
    gallonLimit: 20,
  },
  'Over 20': {
    label: 'Accepts up to 100 gallons',
    shortLabel: 'Accepts up to 100 gal.',
    type: 'site',
    color: 'ff9300',
    hhw: false,
    reuse: false,
    gallonLimit: 10000, // arbitrary value; no limit
  },
  'Over 20 Reuse': {
    label: 'Accepts up to 100  gallons (Reuse)',
    shortLabel: 'Accepts up to 100  gal. (Reuse)',
    type: 'siteReuse',
    color: 'ff9300',
    hhw: false,
    reuse: true,
  },
};
export const text = {
  legend: {
    info: `
      - Blue – Accepts other household hazardous waste
      - Green – Accepts up to 5 gallons
      - Yellow – Accepts up to 20 gallons
      - Orange – Accepts up to 100 gallons
      - Blue with star – Accepts other household hazardous waste and has reuse program
      - Green with star –– Accepts up to 5 gal and has reuse program
      - Yellow with star –– Accepts up to 20 gal and has reuse program
      - Orange with star –– Accepts up to 100 gal and has reuse program
    `,
  },
  program: {
    info: `
        In PaintCare states (those with active paint stewardship programs), the
        PaintCare site locator lists retailers, household hazardous waste
        facilities, and other locations that have partnered with PaintCare to
        accept leftover paint from the public. (PaintCare states include
        California, Colorado, Connecticut, District of Columbia, Maine,
        Minnesota, Oregon, Rhode Island, Vermont, and Washington.

        <br>
        <br>

        In other states, we list locations that accept leftover paint from the
        public, even though these states do not have a paint stewardship
        program.
        
        `,
    disclaimer: `
        All PaintCare sites accept up to 5 gallons per visit (some take more).
        Please call ahead to confirm business hours and ask if they can accept
        the type and amount of paint you would like to recycle. Unless otherwise
        mentioned in the site details, sites do not accept aerosols (spray
        cans), leaking, unlabeled, or empty containers.
    `,
  },

  contact: {
    info: `
    If your organization accepts paint from households and/or businesses,
        contact us and we will be happy to add your information. Please email
        <strong style="color: black">Carol Darrow</strong> at <a style="color: blue; text-decoration: underline;" href="mailto:cdarrow@paint.org"> cdarrow@paint.org</a> or call <a style="color: blue;" href="tel:8557246809">(855) PAINT09</a> to update any
        information about your organization or facility.
    `,
  },
};
