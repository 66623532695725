<template>
  <h2 class="text-22 font-bold">
    <slot />
  </h2>
</template>

<script>
export default {
  name: 'SH2',
};
</script>
