export const loadDevTools = function loadDevTools(callback) {
  const explicitlyDisabled = window.location.search.includes('dev-tools=false')
        || window.localStorage.getItem('dev-tools') === 'false';

  const explicitlyEnabled = window.location.search.includes('dev-tools=true')
        || window.localStorage.getItem('dev-tools') === 'true';

  if (
    !explicitlyDisabled
        && (process.env.NODE_ENV === 'development' || explicitlyEnabled)
  ) {
    // use a dynamic import so the dev-tools code isn't bundled with the regular
    // app code so we don't worry about bundle size.
    import('./dev-tools')
      .then((devTools) => devTools.install())
      .finally(callback);
  } else {
    // if we don't need the DevTools, call the callback immediately.
    callback();
  }
};

export { loadDevTools as default };
