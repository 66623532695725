
export const siteSearch = function(site) {
  return Object.keys(this).every((key) => {
    if (Array.isArray(this[key])) {
      return this[key].indexOf(site[key]) > -1;
    } else {
      return site[key] === this[key];
    }
  })
}
