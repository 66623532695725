<template>
  <div class="dt__container flex flex-row h-full w-full overflow-y-hidden ">
    <SidePane class="w-2/3" />
    <VMap />
  </div>
</template>

<script>

import VMap from '@/components/organisms/VMap.vue';
import SidePane from './organisms/Sidepane.vue';

export default {
  name: 'LocatorDesktop',
  components: {
    SidePane,
    VMap,
  },
  props: { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
