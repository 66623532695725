<template>
  <div class="flex flex-col sidepaneinfo__container h-full w-full p-6">
    <div class="flex justify-end pr-4 pt-4 mb-8"> 
      <div class="hover:bg-grey hover:text-white items-center flex flex-row p-2 cursor-pointer tooltip" @click="handleReturn"> 
        <div class="w-14 h-14 flex justify-center items-center" >
          <ArrowLeftIcon class="w-10 h-10" />
        </div> 
        <span class="mx-4 text-16 font-extrabold"> Site List </span>
      </div>
    </div> 
    <div class="flex flex-col items-start text-left p-6 bg-green">
      <h1 class="text-22 text-white" > <span class="font-medium">Looking for information related to the Site Locator?</span> <span class="font-bold"> You're in the right place. </span> </h1>
    </div>
    <div class="flex flex-col items-start text-left overflow-y-scroll p-4">
      <div class="flex items-center justify-between w-full">
        <h3 class="text-22 text-black font-semibold mb-3">Summary</h3>
      </div>
      <p class="text-16 text-grey-light " v-html="program.info"></p>
      <h3 class="text-22 text-black font-semibold mt-8 mb-3">Use Cases</h3>
      <p class="text-16 text-grey-light " v-html="program.disclaimer"></p>
      <h3 class="text-22 text-black font-semibold mt-8 mb-3">
        Contact
      </h3>
      <p class="text-16 text-grey-light " v-html="contact.info"></p>
    </div>
  </div>
</template>

<script>
import { ArrowLeftIcon } from '@heroicons/vue/outline';
import { text } from '@/common/config/sites.config';

export default {
  name: 'SidePaneSiteInfo',
  components: {
    ArrowLeftIcon,
  },
  methods: {
    handleReturn(){
      this.$emit('toggle:sidepane');
    }
  },
  computed: {
    program() {
      return text.program;
    },
    contact() {
      return text.contact;
    },
  },
};
</script>
