<template>
  <div class="rounded bg-white shadow p-8 flex flex-col items-start text-left w-full md:p-8 card-style">
    <slot />
  </div>
</template>

<style scoped>
 .card-style {
   border: 1px solid lightgray;
 }
</style>
