// eslint-disable-next-line max-classes-per-file
export const PAINT_BASE_URL = 'https://paintcare.dbservices.com';
export const PAINT_NAMESPACE = 'fmi/data/v1/databases/Site%20Locator';

export const AWS_BASE_URL = 'https://a6z4vghb9a.execute-api.us-east-1.amazonaws.com';
export const AWS_NAMESPACE = 'data';

// Assumes env variables exposed via process.env
// export class API {
//   constructor() {
//     this.baseUrl = PAINT_BASE_URL;
//     this.namespace = PAINT_NAMESPACE;
//     this.sessionEnabled = false;
//     this.accessToken = null;
//     this.user = process.env.VUE_APP_PAINT_API_USER;
//     this.password = process.env.VUE_APP_PAINT_API_PASSWORD;
//   }

//   async generateToken() {
//     const url = `${this.baseUrl}/${this.namespace}/sessions`;
//     const creds = btoa(`${this.user}:${this.password}`);
//     console.log(creds); 
//     const options = {
//       method: 'POST',
//       headers: {
//         Authorization: `Basic ${creds}`,
//       },
//     };

//     const response = await this.makeRequest(url, options);
//     const data = response.data();
//     this.setToken(data.token);
//   }

//   setToken(token) {
//     this.accessToken = token;
//   }

//   // eslint-disable-next-line class-methods-use-this
//   errorHandler(error) {
//     // send to sentry
//     // send to UI that we have an error
//     return error;
//   }

//   async makeRequest(url, opt) {
//     /** Generic HTTP Helper Method */

//     const options = {
//       'Content-Type': 'application/json',
//       'Referrer-Policy': 'no-referrer-when-downgrade',
//       mode: 'no-cors',
//       ...opt,
//     };

//     try {
//       const response = await fetch(url, options);
//       if (response.status < 300) {
//         return response.json();
//       }
//       return this.errorHandler('not a 200');
//     } catch (error) {
//       return this.errorHandler(error);
//     }
//   }

//   async querySites(query) {
//     /** Takes formatted query from Filemaker REST API for custom queries */

//     const url = `${this.baseUrl}/${this.namespace}/layouts/SiteAPI/_find`;

//     const options = {
//       method: 'POST',
//       headers: {
//         Authorization: `Bearer ${this.accessToken}`,
//       },
//       body: query,
//     };

//     return this.makeRequest(url, options);
//   }

//   async getSitesByState(stateAbbr) {
//     /** Returns sites by State Abbreviation (e.g. 'VA', 'CA') */

//     const query = {
//       query: [
//         { State: `${stateAbbr}`, omit: 'false' }],
//     };

//     const { data = [] } = await this.querySites(query);
//     return data;
//   }

//   async getAllSites() {
//     const url = `${this.baseUrl}/${this.namespace}/layouts/SiteAPI/records`;

//     const options = {
//       method: 'GET',
//       headers: {
//         Authorization: `Bearer ${this.accessToken}`,
//       },
//     };

//     const { data = [] } = await this.makeRequest(url, options);
//     return data;
//   }
// }

export class AWS_API {
  constructor() {
    this.baseUrl = AWS_BASE_URL;
    this.namespace = AWS_NAMESPACE;
  }

  // eslint-disable-next-line class-methods-use-this
  errorHandler(error) {
    // send to sentry
    // send to UI that we have an error
    return error;
  }

  async makeRequest(url, opt) {
    /** Generic HTTP Helper Method */

    const options = {
      'Content-Type': 'application/json',
      'Referrer-Policy': 'no-referrer-when-downgrade',
      ...opt,
    };

    try {
      const response = await fetch(url, options);
      if (response.status < 300) {
        return response.json();
      }
      return this.errorHandler('not a 200');
    } catch (error) {
      return this.errorHandler(error);
    }
  }

  async getSitesByState(stateAbbr) {
    /** Returns sites by State Abbreviation (e.g. 'VA', 'CA') */

    const options = {
      method: 'GET',
    };
    const url = `${this.baseUrl}/${this.namespace}?state=${stateAbbr}`;

    const { response } = await this.makeRequest(url, options);
    const { data } = response || [];
    return data;
  }
}
