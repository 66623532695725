<template>
  <div v-if="legendOpen" class="absolute bottom-52 right-16 z-20">
    <card>
      <s-h2 class="mb-10">Map Legend</s-h2>
      <div class="flex mb-8" v-for="(item, i) in legendData" :key="i">
        <div class="h-6 w-6 mr-4">
          <img :src="item.icon">
        </div>
        <SP class="w-60">{{ item.title }}</SP>
      </div>
    </card>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import { emitter } from '@/common/utils/emitter';
import YellowBlack from '@/assets/icons/yellow-black.svg';

import BlueBlack from '@/assets/icons/default-l-00b7ff.svg';
import GreenBlack from '@/assets/icons/default-l-8dc114.svg';
import OrangeBlack from '@/assets/icons/default-l-ff9300.svg';
import BlueStar from '@/assets/icons/star-l-00b7ff.svg';
import GreenStar from '@/assets/icons/star-l-8dc114.svg';
import YellowStar from '@/assets/icons/star-l-ffce00.svg';
import OrangeStar from '@/assets/icons/star-l-ff9300.svg';


import Card from '../../atoms/card.vue';
import SH2 from '../../atoms/s-h2.vue';
import SP from '../../atoms/s-p.vue';

export default {
  components: {
    Card,
    SH2,
    SP
  },
  setup() {
    const legendData = ref([
      {
        icon: BlueBlack,
        title: 'Accepts other household hazardous waste',
      },
      {
        icon: GreenBlack,
        title: 'Accepts up to 5 gal',
      },
      {
        icon: YellowBlack,
        title: 'Accepts up to 10 or 20 gal',
      },
      {
        icon: OrangeBlack,
        title: 'Accepts up to 100 gal',
      },
      {
        icon: BlueStar,
        title: 'Accepts other household hazardous waste and has reuse program',
      },
      {
        icon: GreenStar,
        title: 'Accepts up to 5 gal and has reuse program',
      },
      {
        icon: YellowStar,
        title: 'Accepts up to 10 or 20 gal and has reuse program',
      },
      {
        icon: OrangeStar,
        title: 'Accepts up to 100 gal and has reuse program',
      },
    ]);

    const legendOpen = ref(false);

    onMounted(async () => {
      emitter.on('clicked:legend', (event) => {
        legendOpen.value = !legendOpen.value;
      });
    });

    onBeforeUnmount(() => {
      emitter.off('clicked:legend');
    });

    return { legendOpen, legendData };
  },
};
</script>
