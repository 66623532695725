<template>
  <div id="app-inner">
    <div class="router-view__wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, onBeforeUnmount, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { emitter } from '@/common/utils/emitter';
import {useLoading} from 'vue-loading-overlay'

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const $loading = useLoading();
    let loader = ref(null); 

    onMounted(() => {
      emitter.on('switched:state', (s) => {
        store.commit('SET_STATE', s);
        store.dispatch('QUERY_SITES_BY_STATE', s);
        router.replace({ name: 'state-view', params: { id: store.state.selectedState } });
      });
      emitter.on('start:loader', () => {
        loader = $loading.show({ color: "#026fcf" });
      });
      emitter.on('cancel:loader', () => {
        if(loader && loader?.hide) loader.hide();
      });
    });
    onBeforeUnmount(() => {
      emitter.off('switched:state');
      emitter.off('start:loader');
      emitter.off('cancel:loader');
    });

    const stateValue = window.history.state.current.split('/').pop();

    if (stateValue === 'national') {
      router.push({ name: 'national-view' });
      store.commit('BOOT_APP');
      return;
    }
    if (stateValue) {
      store.commit('SET_STATE', stateValue);
      store.dispatch('QUERY_SITES_BY_STATE', stateValue);
    }
    router.push({ name: 'state-view', params: { id: stateValue } });
    store.commit('BOOT_APP');
  },
});
</script>
<style>

html {
  font-size: 62.5%;
  font-family: 'Roboto', sans-serif !important;
}

html body {
  height: 100vh;
  width: 100
}

html, body, #app, #appinner {
  height: 100%;
  margin: 0px;
}

#appinner{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#app-inner {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.router-view__wrapper {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
}

@media only screen and (max-width: 550px) {
  .router-view__wrapper {
    max-width: 400px;
  }
}

/* Works on Firefox */
* {
  scrollbar-color: #59bfff gray;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #f9f9f9;
}

*::-webkit-scrollbar-thumb {
  background-color: #59bfff;
  border-radius: 20px;
  border: 1px solid white;
}


</style>
