import { createApp } from 'vue';
import VueLoading from 'vue-loading-overlay';
import VueVirtualScroller from 'vue-virtual-scroller';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import Store from './store';
import { loadDevTools } from './utils';

import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/mapbox-geocoder.css';
import './assets/mapbox-controls.css';

function init() {
  createApp(App)
    .use(Store)
    .use(router)
    .use(VueLoading)
    .use(VueVirtualScroller)
    .mount('#app');
}
loadDevTools(() => {
  const serverEnabled = process.env.NODE_ENV === 'development'
    || sessionStorage.getItem('pc.localServer');
  if (serverEnabled) {
    import('./server').then((server) => {
      server.makeServer();
      init();
    });
  } else {
    init();
  }
});
