import { icons, siteTypes } from '@/common/config/sites.config';
import { emitter } from '@/common/utils/emitter';
import { FccAPI } from '@/common/services/api.geocoder.js';
import { states } from '@/common/config/map.config';
import store from '@/store';

// Bounding box for our area of interest (Continental United States).
const MIN_LAT = 20;
const MAX_LAT = 55;
const MIN_LONG = -130;
const MAX_LONG = -60;

export const deriveStatefromCensusAPI = async (lat, lng) => {
  const fcc = new FccAPI();
  const response = await fcc.queryLocation(lat, lng);

  const { results } = response;

  if (results.length >= 1) {
    return {
      success: true,
      stateName: results[0].state_code,
    };
  }
  return { success: false };
};

export const lookupStateBounds = (stateCode) => {
  /**
   * @returns bounds (null for not found, if found: bounds array)
   */
  const { bounds } = states.find((state) => state.name === stateCode) || false;
  return bounds;
};

export const handleGeocodingResult = async (evt) => {
  if (evt.result.isState) {
    const { name, bounds } = evt.result;
    store.commit('UPDATE_GEOCODED_BOUNDS', bounds);
    emitter.emit('update:geocode-result', { state: name, type: 'map', bbox: bounds || null });
  } else {
    const [lng, lat] = evt.result.geometry.coordinates;
    const stateId = await deriveStatefromCensusAPI(lat, lng);
    emitter.emit('update:geocode-result', {
      lat, lng, bbox: evt.result.bbox || null, state: stateId.stateName, type: 'map',
    });
  }
};

export const handleHomeGeocodingResult = async (evt) => {
  if (evt.result.isState) {
    const { name, bounds } = evt.result;
    store.commit('UPDATE_GEOCODED_BOUNDS', bounds);
    // need to pass bounds so that update:geocode-result listener can update map
    emitter.emit('update:geocode-result', { state: name, type: 'home' });
  } else {
    const [lng, lat] = evt.result.geometry.coordinates;
    const stateId = await deriveStatefromCensusAPI(lat, lng);
    emitter.emit('update:geocode-result', {
      lat, lng, bbox: evt.result.bbox || null, state: stateId.stateName, type: 'home',
    });
  }
};

export const markerSymbol = (type) => {
  const config = siteTypes[type] || { type: 'hhw', color: 'ff9300' };
  return `${icons[config.type]}-l-${config.color}`;
};

const extractState = (short_code) => short_code.split('-')[1];

export const constructLocationObject = (result) => {
  /** @param result - mapbox geocoding event.result object  */

  const [lng, lat] = result.geometry.coordinates;
  const placeType = result.placetype && result.placetype[0] || null;
  const contextLocations = result.context;
  const stateRegion = contextLocations.find((ctx) => ctx.id.includes('region'));

  const stateCode = extractState(stateRegion.short_code);

  const location = {
    lat,
    lng,
    stateName: stateCode,
    stateLabel: stateRegion.text,
    placeType,
  };

  return location;
};

export const debounce = (func, timeout = 10) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
};

export const addFlashingMarker = (map, lng, lat) => {
  
  const size = 100;
  // This implements `StyleImageInterface`
  // to draw a pulsing dot icon on the map.
  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    // When the layer is added to the map,
    // get the rendering context for the map canvas.
    onAdd() {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
    },

    // Call once before every frame where the icon will be used.
    render() {
      const duration = 1000;
      const t = (performance.now() % duration) / duration;

      const radius = (size / 2) * 0.3;
      const outerRadius = (size / 2) * 0.7 * t + radius;
      const { context } = this;

      // Draw the outer circle.
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(
        this.width / 2,
        this.height / 2,
        outerRadius,
        0,
        Math.PI * 2,
      );
      context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
      context.fill();

      // Draw the inner circle.
      context.beginPath();
      context.arc(
        this.width / 2,
        this.height / 2,
        radius,
        0,
        Math.PI * 2,
      );
      context.fillStyle = 'transparent';
      context.strokeStyle = 'transparent';
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();

      // Update this image's data with data from the canvas.
      this.data = context.getImageData(
        0,
        0,
        this.width,
        this.height,
      ).data;

      // Continuously repaint the map, resulting
      // in the smooth animation of the dot.
      map.value.triggerRepaint();

      // Return `true` to let the map know that the image was updated.
      return true;
    },
  };

  map.on('load', () => {
    map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

    map.addSource('dot-point', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lng, lat], // icon position [lng, lat]
            },
          },
        ],
      },
    });
    map.addLayer({
      id: 'layer-with-pulsing-dot',
      type: 'symbol',
      source: 'dot-point',
      layout: {
        'icon-image': 'pulsing-dot',
      },
    });
  });
};
