<template>
  <h6 class="text-16 text-black">
    <slot />
  </h6>
</template>

<script>
export default {
  name: 'SH6',
};
</script>
