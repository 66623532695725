import { emitter } from '@/common/utils/emitter';

export const geocoderInteractions = () => {
  const results = (payload) => emitter.emit('results:geocoder', payload);

  return { results };
};

export const interactions = () => {
  const markerClicked = (feature) => emitter.emit('clicked:marker', feature);
  const infoClicked = (feature) => emitter.emit('clicked:info', feature);
  const stateSwitch = (feature) => emitter.emit('switched:state', feature);
  const legendClicked = (feature) => emitter.emit('clicked:legend', feature);
  return { markerClicked, infoClicked, stateSwitch, legendClicked };
};
