<template>
  <div class="container relative">
    <site-map />
    <div class="site-index__container flex-grow">
      <Locator />
    </div>
  </div>
</template>

<script>
import Locator from '@/components/LocatorDesktop/index.vue';
import SiteMap from '@/components/LocatorMobile/index.vue';

export default {
  components: { SiteMap, Locator },
  props: {},
};
</script>

<style scoped>
</style>
