<template>
  <DynamicScroller
    v-if="sites && sites.length"
    ref="scroller"
    class="site h-full flex-grow"
    :items="sites"
    :min-item-size="defaultItemSize"
    key-field="Location_ID"
  >
    <template #default="{ item, index, active }">
      <DynamicScrollerItem
        :item="item"
        :active="active"
        :size-dependencies="[item.expanded]"
        :data-index="index"
      >
        <div class="pl-8 pr-8 pt-4 pb-4">
          <card :class="[item.Location_ID === activeId ? 'highlight-card' : '']">
            <SiteDetails class="site-item" :site="item" />
          </card>
        </div>
      </DynamicScrollerItem>
    </template>
  </DynamicScroller>
  <div class="flex flex-col items-center" v-else>
    <ExclamationIcon class="h-10 w-10 mb-2 text-yellow"></ExclamationIcon>
    <s-h3 v-if="selectedState"
      >There are no sites available within selected state
      {{ selectedState }}</s-h3
    >
    <s-h3 v-else>There are no sites found in current view</s-h3>
  </div>
</template>

<script>
import { computed, onMounted, onBeforeUnmount, ref, watch } from 'vue';
import { emitter } from '@/common/utils/emitter';
import { useStore } from 'vuex';
import SiteDetails from '@/components/molecules/SiteDetails.vue';
import card from '@/components/atoms/card.vue';
import sH3 from '@/components/atoms/s-h3.vue';
import {
  ExclamationIcon
} from '@heroicons/vue/solid';

export default {
  name: 'SearchFilters',
  components: {
    SiteDetails,
    card,
    ExclamationIcon,
    sH3,
  },
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const scroller = ref(null);
    const activeIndex = ref(null);
    
    const activeId = computed(() => {
      return store.state.selectedSite
        ? store.state.selectedSite
        : 99999;
    });

    const sites = computed(() => {
      return store.state.spatialFilter
        ? store.getters.visibleSites
        : store.getters.filteredSites;
    });

    // watch([sites, activeId], () => {
    //   console.log('activeId', activeId.value)
    //   const index = sites.value.findIndex((site) => site.Location_ID === activeId.value);
    //   console.log('index', index)
    //   if (index > -1 && scroller.value) {
    //     scroller.value.scrollToItem(index);
    //     activeIndex.value = index;
    //   } else {
    //     activeIndex.value = null;
    //   }
    // });

    onMounted(() => {
      emitter.on('clicked:marker', (id) => {
        store.commit('SET_SITE', id);
        // const index = sites.value.findIndex((site) => site.Location_ID === activeId.value);
        // const parentEl = this.$refs.scroller;

        // parentEl.scrollTo({
        //   top: scrollPosition,
        //   behavior: 'smooth'
        // });




      //   if (index > -1 && scroller.value) {
      //     scroller.value.scrollToItem(index);
      //     activeIndex.value = index;
      //   } else {
      //     activeIndex.value = null;
      //   }
      });
      emitter.on('update:change-site', ({ id }) => {
        store.commit('SET_SITE', id);
      });
    });
    onBeforeUnmount(() => {
      emitter.off('clicked:marker');
      emitter.off('update:change-site');
    });

    const defaultItemSize = 250;

    const selectedState = computed(() => {
      return store.state.selectedState;
    });

    return {
      sites,
      defaultItemSize,
      activeIndex,
      activeId,
      scroller,
      selectedState,
    };
  },
};
</script>

<style> 
  .site{
    height: calc( 100% - 21rem );
  }

  .highlight-card{
    border-color: #FF0000 !important;
    border-width: 3px !important;
  }

</style>
