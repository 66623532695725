<template>
  <div class="w-full" @click="changeSite">
    <div class="">
      <sh2 class="mb-4 text-black">
        {{ site.Name }}
      </sh2>
      <sh6 v-if="site.Name2" class="mb-6 text-black">
        {{ site.Name2 }}
      </sh6>
      <div class="flex items-center justify-between mb-6">
        <div class="flex items-center">
          <LocationMarkerIcon class="w-8 text-black" />
          <sh6 class="ml-4 text-black">
            {{ location }} <span v-if="site.distanceTo"> ({{ site.distanceTo.toFixed(2) }} miles from searched location)</span> 
          </sh6>
        </div>
        <template v-if="site.isReuse">
          <div class="flex flex-col items-center w-1/2">
            <BadgeCheckIcon class="w-8 text-black" />
            <sh6 class="ml-4 text-black">
              Supports Reuse
            </sh6>
          </div>
        </template>
        <template v-if="site.isHHW">
          <div class="flex items-center w-1/2">
            <BadgeCheckIcon class="w-8 text-black" />
            <sh6 class="ml-4 text-black">
              Accepts HHW
            </sh6>
          </div>
        </template>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center w-1/2">
          <PhoneIcon class="w-8 text-black" />
          <sh6 class="ml-4 text-black">
            {{ site.Phone_Line ? site.Phone_Line : 'Not available' }}
          </sh6>
        </div>
        <div class="flex items-center w-1/2">
          <BadgeCheckIcon class="w-8" :style="{ color: calcPinColor(site.Pin_Code)}" />
          <sh6 class="ml-4 text-black"> Accepts {{ site.Pin_Code }} </sh6>
        </div>
      </div>
    </div>
    <div class="mt-6"> 
    <div class="flex items-center justify-between flex-row">
      <button
        class="flex items-center cursor-pointer site-use-btn"
        @click.native="toggleDetails"
      >
        <ChevronDownIcon v-if="!expanded" class="w-6 text-blue" />
        <ChevronUpIcon v-if="expanded" class="w-6 text-blue" />
        <slink class="ml-4">
          More Information
        </slink>
      </button>
      <!-- <button
        class="inline-flex text-blue text-16 cursor-pointer find-map-btn"
      >
        <LocationMarker class="w-8 mr-3" />
        Find on Map
      </button> -->
    </div>
    <div v-if="expanded" :key="site.Location_ID" class="mt-2 pl-4">
      <extra-site-details :site="site" />
    </div>
  </div> 
  </div>
</template>

<script>
import { emitter } from '@/common/utils/emitter';
import { siteTypes } from '@/common/config/sites.config';

import {
  LocationMarkerIcon,
  PhoneIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  BadgeCheckIcon,
} from '@heroicons/vue/outline';

import { LocationMarkerIcon as LocationMarker } from '@heroicons/vue/solid';

import sh2 from '@/components/atoms/s-h2.vue';
import sh3 from '@/components/atoms/s-h3.vue';
import sh6 from '@/components/atoms/s-h6.vue';
import slink from '@/components/atoms/s-link.vue';
import ExtraSiteDetails from '@/components/molecules/ExtraSiteDetails.vue';

export default {
  name: 'SiteDetails',
  components: {
    sh2,
    sh3,
    sh6,
    slink,
    LocationMarkerIcon,
    LocationMarker,
    PhoneIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    BadgeCheckIcon,
    ExtraSiteDetails,
  },
  props: {
    site: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      expanded: false,
      siteTypes,
    };
  },
  computed: {
    location() {
      return `${this.site.City}, ${this.site.State}`;
    },
  },
  created(){
    emitter.on('update:site-details', (id, ) => {
      if (id !== this.site.Location_ID) {
        this.expanded = false;
      }
    });
    emitter.on('clear:site-details', (id, ) => {
      this.expanded = false;
    });
  },

  beforeUnmount() {
    emitter.off('update:site-details');
    emitter.off('clear:site-details');
  },

  methods: {
    toggleDetails() {
      if (!this.expanded) emitter.emit('update:site-details', this.site.Location_ID);
      this.expanded = !this.expanded;
    },
    changeSite() {
      const id = this.site.Location_ID;
      console.log('changeSite', id);
      emitter.emit('update:change-site', { site: this.site, id });
      this.$emit('switch-to-map'); //emit to parent to toggle
    },
    calcPinColor(pinCode) {
      const type = this.siteTypes[pinCode];
      if (type) return type.color; 
      return 'text-darkgrey';
    },
  },
};
</script>

<style scoped>

  .site-use-btn, .find-map-btn {
    padding: 6px;
    border: solid 1px transparent;
    border-radius: 3px;
  }
 .site-use-btn:hover, .find-map-btn:hover {
    color: #87C1FF;
    border-radius: 3px;
    border: solid 1px lightgray;
  }

</style>
