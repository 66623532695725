<template>
  <div class="extra-site-details__wrapper">
    <p class="mb-2 flex flex-col justify-start" v-if="details.address">
      <h4><strong>Address: </strong></h4>
      <div> {{details.address}}  </div>
    </p>

    <p v-if="details.URL" class="mb-2 flex flex-col justify-start">
      <h4><strong>Website: </strong></h4>
      <a class="website" :href="details.URL" target="_blank" rel="noopener">{{ details.URL }}</a>
    </p>

    <p class="mb-2 flex flex-col justify-start">
      <h4><strong>Hours: </strong></h4>
       <template v-if="details.HoursDisplay"> 
        <div> {{details.HoursDisplay}} </div>
      </template>
      <template v-else>
        <span> Contact site for business hours. </span>
      </template>
    </p>

    <p class="mb-2 flex flex-col justify-start">
      <h4><strong>Who Can Use This Program: </strong></h4>
      <template v-if="details.ForType"> 
        <div> {{details.ForType}} </div>
      </template>
      <template v-else>
        <span> Not Available. </span>
      </template>
    </p>

    <p class="mb-2 flex flex-col items-start">
      <h4><strong>Information: </strong></h4>
      <template v-if="details.comments && details.comments.length > 0"> 
        <div class="text-left mt-1"> {{details.comments}} </div>
      </template>
      <template v-else>
        <span> Not Available. </span>
      </template>
    </p>

    <p class="mb-2 flex flex-col justify-start" v-if="details.Reuse_Statement && details.Reuse_Statement.length > 0">
      <h4><strong>Reuse Program: </strong></h4>
      <div> {{details.Reuse_Statement}} </div>

    </p>
    <p class="mb-2 flex justify-start">
      <template > 
        <div> {{PaintCareStatement}} </div>
      </template>
    </p>


  </div>
</template>

<script>
import sLink from '@/components/atoms/s-link.vue';
import { gatherExtendedDetails } from '@/common/utils/translator.utils';
import { reactive } from 'vue';

export default {
  components: {
    sLink,
  },
  props: {
    site: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const details = reactive(gatherExtendedDetails(props.site));
    return {
      details,
    }
  },
};
</script>

<style scoped>
 .website {
   color: blue; 
   text-decoration: underline;
   word-wrap: break-word;
 }

 h4, p, a {
  font-size: 15px;
 }

 .extra-site-details__wrapper{
  animation-name: expand;
  animation-duration: .2s;
  animation-timing-function: ease-out;
 }

 @keyframes expand {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
}

</style>
